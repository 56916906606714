import { Component } from '@angular/core';
import firebase from 'firebase/app';
import { firebaseConfig } from './app.firebase.config';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor() {
    this.initializeApp()
  }

  initializeApp() {
    firebase.initializeApp(firebaseConfig);
  }
}
